/* Main CSS */

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.swal2-container {
	z-index: 10000 !important;
}
